<template>
    <div>
      <v-app class="desktopLayoutClass">
        <div>
          <v-row justify="center">
            <v-dialog v-model="auth_error_enable" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break: normal">
                   {{$t("Warning_Message.Session_Expired_Please_Login_Again")}}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    depressed
                    @click="closeDialogUnauthorized"
                  >
                    {{$t("Warning_Message.Login")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
  
        <div>
          <v-row justify="center">
            <v-dialog v-model="error_enable" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break: normal">
                   {{$t("Warning_Message.Something_Went_Wrong")}}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed color="primary" @click="closeDialogError">
                    {{$t("Warning_Message.Ok")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
  
        <div>
            <v-row justify="center">
                <v-dialog v-model="error_enable" persistent max-width="290">
                <v-card>
                    <v-card-title style="word-break:normal">
                    {{$t("Warning_Message.Something_Went_Wrong")}}
                    </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed
            color="primary" @click="closeDialogError">
                      {{$t("Warning_Message.Ok")}}
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </div>
  
        <div>
          <v-row justify="center">
            <v-dialog
              v-model="required_fields_error_enable"
              persistent
              max-width="290"
            >
              <v-card>
                <v-card-title style="word-break: normal">
                  {{$t("Warning_Message.Please_Fill_All_Details")}}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    color="primary"
                    @click="closeRequiredDialogError"
                  >
                     {{$t("Warning_Message.Ok")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>

        <div>
          <v-row justify="center">
            <v-dialog v-model="phoneNumberError" max-width="290">
              <v-card>
                <v-card-title style="word-break:normal">
                  {{$t("Warning_Message.Please_Enter_Your_Number_With0In_The_Beginning")}}
                </v-card-title>
                <v-card-text
                ></v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    align="center"
                    depressed
                    color="primary"
                    @click="disableLoading"
                    >
                  {{$t("Warning_Message.Ok")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>

        <div class="mainDivaddDependent">

        <div
          v-show="show_header"
          class="header"
          style="
            display: flex;
            flex-direction: row;
            padding-top: 10px;
            padding-left: 5px;
            padding-bottom: 10px;
          "
          >
          <div style="display: flex; flex-direction: row; width: 100%; align-items: center;">
            <div
              style="width: 10%; padding-top: 6px"
              align="left"
              v-on:click="redirectToMyAddressBook()"
            >
              <img 
                src="https://d3vsnl8idgwrlw.cloudfront.net/goToArrowIcon.svg"
              />
            </div>
            <div style="width: 90%" align="left">
              <span style="font-weight: 600; font-size: 15pt">{{ $t("Customer.UserProfile.Add_Address") }}</span>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <div>
          <v-row justify="center">
            <v-dialog v-model="editProfileSuccessModal" max-width="290">
              <v-card>
                <v-card-title style="word-break: normal; margin-top: 20px">
                  {{$t("Warning_Message.Address_Added")}}
                </v-card-title>
                <v-card-text></v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    align="center"
                    color="primary"
                    depressed
                    @click="closeEditProfileSuccessModal"
                  >
                     {{$t("Warning_Message.Ok")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>

          <div class="PageClass" style="padding: 10px 20px !important; margin-top:25px;">
            <!-- DELIVER TO -->
            <p
            style="
              text-align: left;
              padding-bottom: 0px;
              margin-bottom: 0px;
              margin-top: 7px;
              font-weight: 400 !important;
              color: #828282
            "
          >
            {{ $t("Customer.AddAddress.Deliver_To") }}<span style="color:#828282">*</span>
            </p>
            <v-text-field
              v-model="address_deliver_to"
              outlined
              :placeholder="translation_enter_name"
              class="textFieldClass"
              :rules="$store.state.locale == 'en' ? nameRules : nameRulesMM"
            />
            <!-- MOBILE NUMBER -->
            <p
              style="
                text-align: left;
                padding-bottom: 0px;
                margin-bottom: 0px;
                margin-top: 7px;
                font-weight: 400 !important;
                color: #828282
                "
              >
                {{ $t("Customer.AddAddress.Mobile_Number") }}<span style="color:#828282">*</span>
              </p>
              <v-text-field
                v-model="address_mobile_number"
                outlined
                :placeholder="translation_enter_number"
                :rules="$store.state.locale == 'en' ? mobileNumberRules : mobileNumberRulesMM"
                type="number"
                class="textFieldClass"
                prefix="+95"
                placeholder="09*********"
              />
            <!-- HOUSE NUMBER -->
            <p
              style="
                text-align: left;
                padding-bottom: 0px;
                margin-bottom: 0px;
                margin-top: 7px;
                font-weight: 400 !important;
                color: #828282
              "
            >
              {{ $t("Customer.AddAddress.House_Number_Building_Name") }}<span style="color:#828282">*</span>
            </p>
            <v-text-field
                v-model="address_house_building"
                outlined
                :placeholder="translation_enter_house_building"
                :rules="$store.state.locale == 'en' ? houseBuildingRules : houseBuildingRulesMM"
                class="textFieldClass"
            />
            <!-- STREET LOCALITY -->
            <p
              style="
                text-align: left;
                padding-bottom: 0px;
                margin-bottom: 0px;
                margin-top: 7px;
                font-weight: 400 !important;
                color: #828282
              "
            >
              {{ $t("Customer.AddAddress.Street_Name_Locality") }}<span style="color:#828282">*</span>
            </p>
            <v-text-field
            v-model="address_street_locality"
            outlined
            :placeholder="translation_enter_street_locality"
            :rules="$store.state.locale == 'en' ? streetLocality : streetLocalityMM"
            class="textFieldClass"
            />
            <!-- LANDMARK -->
            <p
              style="
                text-align: left;
                padding-bottom: 0px;
                margin-bottom: 0px;
                margin-top: 7px;
                font-weight: 400 !important;
                color: #828282
              "
            >
              {{ $t("Customer.AddAddress.Landmark") }}
            </p>
            <v-text-field
            v-model="address_landmark"
            outlined
            :placeholder="translation_enter_landmark"
            class="textFieldClass"
            />
            <!-- STATE -->
            <p
              style="
                text-align: left;
                padding-bottom: 0px;
                margin-bottom: 0px;
                margin-top: 7px;
                font-weight: 400 !important;
                color: #828282;
              "
            >
              {{ $t("Customer.AddAddress.State") }}
            </p>
            <v-select
              v-model="selectedState"
              :items="statesInfo"
              outlined
              class="textFieldClass"
              item-text="name"
              item-value="id"
              solo
              flat
              :label="translation_select_state"
              return-object
            >
              <template #append>
                <img class="mt-1"  src="https://d3vsnl8idgwrlw.cloudfront.net/StateDropdownIcon.png" alt="Search Icon">
              </template>
            </v-select>
            <!-- ADDRESS TYPE -->
            <p
              style="
                text-align: left;
                padding-bottom: 0px;
                margin-bottom: 0px;
                margin-top: 7px;
                font-weight: 400 !important;
                color: #828282
              "
            >
              {{ $t("Customer.AddAddress.Address_Type") }}<span style="color:#828282">*</span>
            </p>
            <div class="addressTypeButtons">
              <div style="width: 30%;">
                <v-btn
                  width="100%"
                  elevation="0" 
                  @click="setAddressType('Home')"
                  :style="{ color: address_type==='Home' ? 'white' : 'black', backgroundColor: address_type==='Home' ? '#48ACEF' : 'white', textTransform: 'none', border: address_type != 'Home' ? '1px solid #E0E0E0' : 'none'}"
                >
                  <img src="https://d3vsnl8idgwrlw.cloudfront.net/AddressHomeWhiteIcon.svg" style="padding-right: 10%;" v-if="address_type==='Home'">
                  <img src="https://d3vsnl8idgwrlw.cloudfront.net/AddressHomeIcon.svg" style="padding-right: 10%;" v-else>
                  {{ $t("Customer.Home.Home")}}
                </v-btn>
              </div>
              <div style="width: 30%;">
                <v-btn 
                  width="100%"
                  elevation="0" 
                  @click="setAddressType('Work')"
                  :style="{ color: address_type==='Work' ? 'white' : 'black', backgroundColor: address_type==='Work' ? '#48ACEF' : 'white', textTransform: 'none', border: address_type != 'Work' ? '1px solid #E0E0E0' : 'none'}"
                >
                  <img v-if="address_type==='Work'" src="https://d3vsnl8idgwrlw.cloudfront.net/AddressWorkWhiteIcon.svg" style="padding-right: 10%;">
                  <img v-else src="https://d3vsnl8idgwrlw.cloudfront.net/AddressWorkIcon.svg" style="padding-right: 10%;">
                  {{ $t("Customer.AddAddress.Work")}}
                </v-btn>
              </div>
              <div style="width: 30%;">
                <v-btn
                  width="100%"
                  elevation="0" 
                  @click="setAddressType('Others')"
                  :style="{ color: address_type==='Others' ? 'white' : 'black', backgroundColor: address_type==='Others' ? '#48ACEF' : 'white', textTransform: 'none', border: address_type != 'Others' ? '1px solid #E0E0E0' : 'none'}"
                >
                {{ $t("Customer.AddAddress.Others")}}
                </v-btn>
              </div>
            </div>
            <v-text-field
            v-if="address_type==='Others'"
            v-model="address_name"
            outlined
            :placeholder="translation_enter_address_name"
            class="textFieldClass"
            />          
          </div>
        </div>

        <v-divider></v-divider>

        <div class="mainDivaddDependent">
          <div class="PageClass PageClassForSaveButton">
            <!-- SAVE BUTTON -->
            <div>
              <v-row>
                <v-col>
                  <v-btn
                    elevation="0"
                    style="
                      width: 98%;
                      height: 48px;
                      margin-bottom: 2%;
                      align: center;
                      text-transform: none;
                      color: white;
                      background-color: #48ACEF;
                    "
                    @click="saveAddressInfo"
                    :disabled="isSaved || !(this.address_deliver_to != '' && (this.address_mobile_number && this.address_mobile_number.length>=7 && this.address_mobile_number.length<=11 && this.address_mobile_number.toString()[0]=='0') &&
                                            this.address_house_building !='' && this.address_street_locality != '' && this.address_type != '' &&
                                            ((this.address_type==='Others' && this.address_name!='') || (this.address_type==='Home') || (this.address_type==='Work'))
                                          )"
                  >
                    {{ $t("Customer.AddAddress.Save_Changes") }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

      </v-app>
    </div>
  </template>
  
  <script>
  import NavBar from "../../../navbar.vue";
  import axios from "axios";
  import bottomnavbar from "../bottomnavbar.vue";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import { axios_auth_instance_epharmacy_customer } from '../../../../utils/axios_utils';
  export default {
    name: "AddAddress",
    data() {
      return {
        phoneNumberError: false,
        saveChangesDisabled: true,
        address_deliver_to: "",
        address_mobile_number: "",
        address_house_building: "",
        address_street_locality: "",
        address_landmark: "",
        address_type: "",
        address_name: "",
        submit_button: "",
        customer_id: "",
        customer_name: "",
        phoneNumber: "",
        currentCustomer: "",
        auth_error_enable: false,
        error_enable: false,
        editProfileSuccessModal: false,
        required_fields_error_enable: false,
        nameRules: [(v) => !!v || "Name is required"],
        nameRulesMM: [(v) => !!v || "အမည်ထည့်သွင်းရန် လိုအပ်ပါသည်။"],
        mobileNumberRules: [
          v => !!v || 'Mobile Number is required',
          v => (v && v.length >=7 && v.length <= 11) || 'Mobile Number should be between 7 to 11 numbers',
          v => v > 0 || 'Mobile number cannot be negative',
          v => this.checkZero(v) || 'Mobile number should start with 0'
        ],
        mobileNumberRulesMM: [
          v => !!v || 'ဖုန်းနံပါတ်ထည့်သွင်းရန် လိုအပ်ပါသည်။',
          v => (v && v.length >=7 && v.length <= 11) || 'ဖုန်းနံပါတ်၏ ဂဏန်းအရေအတွက်မှာ ၇ လုံးမှ ၁၁ လုံးဖြစ်ရပါမည်။',
          v => v > 0 || 'ဖုန်းနံပါတ်တွင် အနုတ် (-) ထည့်၍ မရပါ',
          v => this.checkZero(v) || 'Mobile number should start with 0 (mm)'
        ],
        houseBuildingRules: [(v) => !!v || "House Number / Building Name is required"],
        houseBuildingRulesMM: [(v) => !!v || "House Number / Building Name is required (Burma)"],
        streetLocality: [(v) => !!v || "Street Name / Locality is required"],
        streetLocalityMM: [(v) => !!v || "Street Name / Locality is required (Burma)"],
        isMobile: false,
        isSaved: false,
        translation_enter_name: (this.$i18n.locale == "en") ? "Enter Name" : "အမည်",
        translation_enter_number: (this.$i18n.locale == "en") ? "Enter Mobile Number" : "ဖုန်းနံပါတ်",
        translation_enter_house_building: (this.$i18n.locale == "en") ? "Enter House Number / Building Name" : "Enter Enter House Number / Building Name (Burma)",
        translation_enter_street_locality: (this.$i18n.locale == "en") ? "Enter Street Name / Locality" : "Enter Street Name / Locality (Burma)",
        translation_enter_landmark: (this.$i18n.locale == "en") ? "Eg. near to cloth shop" : "Eg. near to cloth shop (Burma)",
        translation_select_state: (this.$i18n.locale == "en") ? "Select State" : "Select State (Burma)",
        translation_enter_address_name: (this.$i18n.locale == "en") ? "Eg. Shop address" : "Eg. Shop address (Burma)",
        show_header: true,
        phoneNumber_check:false,
        statesList: [],
        selectedState: "",
        statesInfo: []
      };
    },
    methods: {
      checkZero(number) {
        return !number || number.toString()[0] == "0";
      },
      disableLoading(){
        this.phoneNumberError = false;
        this.isSaved = false;
      },
      setAddressType(type) {
        this.address_type = type;
      },
      closePhoneNumberModal(){
        this.phoneNumber_check = false
         this.isSaved = false;
      },
      closeDialogUnauthorized() {
        this.auth_error_enable = false;
        this.$router.push({
          name: "Login",
        });
      },
      closeRequiredDialogError() {
        this.required_fields_error_enable = false;
      },
      closeDialogError() {
        this.error_enable = false;
      },
      redirectToMyAddressBook() {
        this.$router.push({
          name: "MyAddressbook",
        });
      },
      closeEditProfileSuccessModal() {
        this.editProfileSuccessModal = false;
      },
      saveAddressInfo() {
        this.isSaved = true;
        if(this.address_mobile_number[0] != "0") {
          this.phoneNumberError = true;
        }
        else if (this.address_deliver_to && this.address_mobile_number &&
            this.address_house_building && this.address_street_locality && this.address_type)
        {
            let addressTypeName;
            if(this.address_type === 'Others' || this.address_type === 'OTHERS')
              addressTypeName = this.address_name;
            else
              addressTypeName = this.address_type;  

            var address_body = {
              deliverTo: this.address_deliver_to,
              mobile: this.address_mobile_number,
              addressLine1: this.address_house_building,
              addressLine2: this.address_street_locality,
              landmark: this.address_landmark,
              stateId: this.selectedState.id,
              addressTypeName: addressTypeName
            };

            axios_auth_instance_epharmacy_customer
            .post("/address/", address_body)
            .then((saveAddressInfoResponse) => {
              this.$router.push({
                path: '/customer/address'
              });
            })
            .catch((saveAddressInfoError) => {
              console.log('[ERROR][AddAddressError]: ', saveAddressInfoError);
              this.isSaved = false;
            });
        }
        else {
          this.required_fields_error_enable = true;
          this.isSaved = false;
        }
      },
      userPageViewTable() {
        if (this.currentCustomer === null) {
            var userPageViewTableBody = {
            pageName : 'addAddressPage',
            typeOfUser: 'CUSTOMER'
            }
        } else {
            var userPageViewTableBody = {
            pageName: 'addAddressPage',
            typeOfUser: 'CUSTOMER',
            token: this.currentCustomer
            }
        }
        axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
        .catch((userPageViewTableError) => {
            console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
        });
      },
    },
    mounted() {
      var userAgent = navigator.userAgent
      if(userAgent.includes("kbzpay")){
        this.show_header = false;
      }
      this.isMobile = this.$device.mobile;
      this.$i18n.locale = this.$store.state.locale;
      if(this.$i18n.locale == "en")
      {
        this.translation_enter_name = "Enter Name";
        this.translation_enter_number = "Enter Mobile Number",
        this.translation_enter_house_building = "Enter House Number / Building Name",
        this.translation_enter_street_locality = "Enter Street Name / Locality",
        this.translation_enter_landmark = "Eg. near to cloth shop",
        this.translation_enter_address_type = "Eg. Shop address",
        document.title = "Add Address"
      }
      else
      {
        this.translation_enter_name = "အမည်";
        this.translation_enter_number = "ဖုန်းနံပါတ်",
        this.translation_enter_house_building = "Enter House Number / Building Name (Burma)",
        this.translation_enter_street_locality = "Enter Street Name / Locality (Burma)",
        this.translation_enter_landmark = "Eg. near to cloth shop (Burma)",
        this.translation_enter_address_type = "Eg. Shop address (Burma)",
        document.title = "Add Address (Burma)"
      }
      this.currentCustomer = this.$cookies.get("customerToken");
      if (!this.currentCustomer) {
        this.$router.push({
          name: "CommonHomePage",
        });
      } else {
        axios_auth_instance_epharmacy_customer.get("/address/stateCountryList")
        .then((getStatesInfoSuccess) => {
            this.statesInfo = getStatesInfoSuccess.data.data.listOfState;
        })
        .catch((getStatesInfoError) => {
            if (getStatesInfoError.status === 401) {
              this.$cookies.remove('customerToken');
              if (!window.xm) {
                  this.$router.push({
                      name: 'CustomerHomeScreen'
                  });
              } else {
                  this.$router.push({
                      name: 'CommonHomePage'
                  });
              }
            } else {
              console.log('[Error] ', getStatesInfoError);
            }
        });
        this.userPageViewTable();
      }
    },
  };
  </script>
  
<style scoped>
.PageClassForSaveButton {
    padding: 0px 20px !important;
    margin-top:20px;
  }
.textFieldClass {
  width: 100%;
  border-radius: 5px;
  margin-right: 10px;
  margin-left: 0px;
  margin-top: 7px;
}
.addressTypeButtons{
  width: 100%;
  padding-top: 10px; 
  padding-bottom: 20px; 
  display:flex; 
  justify-content: space-between !important;
}
</style>

  <style>
  .font_size_12_400 {
    font-size: 14px;
    font-weight: 400;
  }
  .mx-input {
    height: 40px !important;
    border-bottom: 1px solid #ccc !important;
    border-radius: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  /* .v-picker--full-width {
    height: 500px;
  } */
  
  
  /* @media (min-width: 800px){
    .mainDivaddDependent{
     width: 800px !important;
     margin-left: auto !important ;
      margin-right: auto !important ;
    }
    .PageClass{
       margin-top: 25px !important;
    }
    } */
  
  </style>
  <style>
  .v-select__selection {
    line-height: 20px !important;
  }
  .v-select__slot.v-select__selection.v-select__selection--comma{
    line-height: 20px !important;
  }
  </style>
  